import FF1 from '@/images/lovestory/1-ff-1.jpg';
import FF2 from '@/images/lovestory/1-ff-2.jpg';
import MTF from '@/images/lovestory/2.jpg';
import GD18 from '@/images/lovestory/3-2018.jpg';
import GD19 from '@/images/lovestory/3-2019.jpg';
import GD21 from '@/images/lovestory/3-2021.jpg';
import JB1 from '@/images/lovestory/4-1.jpg';
import JB2 from '@/images/lovestory/4-2.jpg';

export default [
  {
    images: [FF1, FF2],
    title: 'Friends First',
    content: `Our story began when Nicole first came to our church in 2013. At first, she was just the new girl and I was just one of the guys in the youth group. As we got to know each other, we started to become good friends… but seriously… we were JUST FRIENDS. There were no romantic feelings, no physical or emotional attraction, but we just had fun hanging out with each other. The truth was, we actually liked other people at the time, and we were happy for one another. We supported, encouraged and cheered each other on, yet little did we know, that turned out to be the foundation of something special…`,
  },
  {
    images: [MTF],
    title: 'More than Friends',
    content: `As we went through our own fair share of the typical teenage experiences of heartbreak, disappointment and surprises, we continued to lean on each other for support and a small spark eventually came to light. Texts became more frequent, phone calls started to lengthen, and goodbyes became longer and started to end with “sleep tight and sweet dreams”. After months of continued flirting and newfound excitement, we finally confessed our feelings and went on our first date in February 2015. In a flash, we were out of the friendzone and now, we were more than friends… `,
  },
  {
    images: [GD18, GD19, GD21],
    title: 'Going the Distance',
    content: `“Distance makes the heart grow fonder”. How true are those words, though you can only appreciate them after you’ve experienced it for yourself. The test for us began when Nicole had to move back to Indonesia in February 2016, just days after we planned and celebrated my parents’ 25th Anniversary party together. It was the start of a long journey filled with laughter, tears, longingness, frustration, hope, but one that revealed God’s heart and plan for us. Our long-distance relationship ended up lasting 5 long years, but it was a time much needed for us to learn how to fully trust in God’s perfect will for us…`,
  },

  {
    images: [JB1, JB2],
    title: 'Just the Beginning',
    content: `In between the months and years of not seeing each other, we realized that we needed that time for God to heal us individually from our past and to prepare us for the beautiful plan that He always had in mind: Marriage. It was in the quietness of our time alone that each of us received the clear answer we needed, that Nicole was the Proverbs 31 wife that was promised and that Kevin was the man that God promised through prophecy.
      <br />
      <br />
      <i>“Trust in the Lord with all your heart and lean not on your own understanding; in all your ways acknowledge Him, and He shall direct your path” 
      <br />(Proverbs 3:5)</i>  
      <br /><br />    
      ... and our love story is just being written…
    `,
  },
];
