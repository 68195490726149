import React from 'react';
import { arrayOf, object } from 'prop-types';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Text, Image, AspectRatio } from '@chakra-ui/react';

import loveStories from './lovestory-data';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

SwiperCore.use([Pagination]);

function LoveStorySwapable({ story, ...rest }) {
  return (
    <Swiper centeredSlides pagination={{ dynamicBullets: true }} autoHeight className="mySwiper">
      {story.map((i) => {
        return (
          <SwiperSlide key={i.title}>
            <Box padding="16px" borderRadius="16px" bgColor="alternativeDarkTransparent" {...rest}>
              <Text
                marginTop="16px"
                marginLeft="4px"
                color="#fff"
                fontFamily="heading"
                fontSize="2xl"
                marginBottom="16px"
              >
                {i.title}
              </Text>
              {i.img && (
                <AspectRatio ratio={1 / 1}>
                  <Image src={i.img} maxWidth="100%" borderRadius="8px" />
                </AspectRatio>
              )}
              {i.images?.length > 0
                ? i.images.map((item, index) => {
                    return (
                      <AspectRatio key={index} ratio={1 / 1} margin="4px 0">
                        <Image src={item} maxWidth="100%" borderRadius="8px" />
                      </AspectRatio>
                    );
                  })
                : null}
              <Text
                margin="16px 0"
                marginBottom="32px"
                color="mainColorText"
                dangerouslySetInnerHTML={{ __html: i.content }}
              />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

LoveStorySwapable.propTypes = {
  story: arrayOf(object),
};

LoveStorySwapable.defaultProps = {
  story: loveStories,
};

export default LoveStorySwapable;
